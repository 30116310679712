import Head from "next/head";

import { CallToAction } from "@/components/CallToAction";
import { Footer } from "@/components/Footer";
import { Header } from "@/components/Header";
import { Hero } from "@/components/Hero";
import { Pricing } from "@/components/Pricing";
import { PrimaryFeatures } from "@/components/PrimaryFeatures";

export default function Home() {
	return (
		<>
			<Head>
				<title>Octolense - GitHub Actions Dashboard</title>
				<meta
					name="description"
					content="Octolense: The Ultimate GitHub Actions Dashboard. Gain insights, optimize workflows, and track performance. Sign up now!"
				/>
				<link rel="canonical" href="https://octolense.com" />
				<meta property="og:title" content="Octolense - GitHub Actions Dashboard" />
				<meta
					property="og:description"
					content="Discover Octolense, the ultimate GitHub Actions Dashboard for streamlined DevOps workflows. Gain insights, optimize efficiency, and take control of your GitHub Actions. Sign up now!"
				/>
				<meta property="og:image" content="https://octolense.com/og.png" />
				<meta property="og:url" content="https://octolense.com" />
			</Head>
			<Header />
			<main>
				<Hero />
				<PrimaryFeatures />
				{/* <SecondaryFeatures /> */}
				<CallToAction />
				{/* <Testimonials /> */}
				<Pricing />
				{/* <Faqs /> */}
			</main>
			<Footer />
		</>
	);
}
