import { useEffect, useState } from "react";
import Image from "next/image";
import { Tab } from "@headlessui/react";
import clsx from "clsx";

import { Container } from "@/components/Container";
import backgroundImage from "@/images/background-grey.png";
import screenshotRepoOverview from "@/images/screenshots/repos-overview-new.png";
import screenshotOrganizations from "@/images/screenshots/organizations.png";
import screenshotInstallation from "@/images/screenshots/github-app.png";

const features = [
	{
		title: "Repository Insights",
		description: "Get insights into your GitHub Actions usage and costs on a repository level.",
		image: screenshotRepoOverview,
	},
	{
		title: "Organizations & Accounts",
		description:
			"Understand the usage of GitHub Actions across all your organizations and accounts.",
		image: screenshotOrganizations,
	},
	{
		title: "Easy and Safe Setup",
		description:
			"No need to change your workflows, simply install our GitHub App and get insights in seconds.",
		image: screenshotInstallation,
	},
	// {
	//   // Future
	//   title: 'Alerts & Reports',
	//   description:
	//     'Get notified when a repository exceeds a certain budget. Get reports on your Github Actions usage and costs.',
	//   image: screenshotReporting,
	// },
];

export function PrimaryFeatures() {
	let [tabOrientation, setTabOrientation] = useState("horizontal");

	useEffect(() => {
		let lgMediaQuery = window.matchMedia("(min-width: 1024px)");

		function onMediaQueryChange({ matches }) {
			setTabOrientation(matches ? "vertical" : "horizontal");
		}

		onMediaQueryChange(lgMediaQuery);
		lgMediaQuery.addEventListener("change", onMediaQueryChange);

		return () => {
			lgMediaQuery.removeEventListener("change", onMediaQueryChange);
		};
	}, []);

	return (
		<section
			id="features"
			aria-label="Features for Octolense"
			className="relative overflow-hidden pb-28 pt-20 sm:py-32"
		>
			<Image
				className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
				src={backgroundImage}
				alt="Background image for Octolense features section"
				width={2245}
				height={1636}
				unoptimized
			/>
			<Container className="relative">
				<div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
					<h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
						Get insights into your GitHub Actions Usage.
					</h2>
					<p className="mt-6 text-lg tracking-tight text-blue-100">
						We give you the tools to understand your GitHub Actions usage and costs on an
						organization and repository level.
					</p>
				</div>
				<Tab.Group
					as="div"
					className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
					vertical={tabOrientation === "vertical"}
				>
					{({ selectedIndex }) => (
						<>
							<div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
								<Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
									{features.map((feature, featureIndex) => (
										<div
											key={feature.title}
											className={clsx(
												"group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6",
												selectedIndex === featureIndex
													? "bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10"
													: "hover:bg-white/10 lg:hover:bg-white/5",
											)}
										>
											<h3>
												<Tab
													className={clsx(
														"font-display text-lg [&:not(:focus-visible)]:focus:outline-none",
														selectedIndex === featureIndex
															? "text-blue-600 lg:text-white"
															: "text-blue-100 hover:text-white lg:text-white",
													)}
												>
													<span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
													{feature.title}
												</Tab>
											</h3>
											<p
												className={clsx(
													"mt-2 hidden text-sm lg:block",
													selectedIndex === featureIndex
														? "text-white"
														: "text-blue-100 group-hover:text-white",
												)}
											>
												{feature.description}
											</p>
										</div>
									))}
								</Tab.List>
							</div>
							<Tab.Panels className="lg:col-span-7">
								{features.map((feature) => (
									<Tab.Panel key={feature.title} unmount={false}>
										<div className="relative sm:px-6 lg:hidden">
											<div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
											<p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
												{feature.description}
											</p>
										</div>
										<div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-gray-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
											<Image
												className="w-full"
												src={feature.image}
												alt={feature.description}
												priority
												sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
											/>
										</div>
									</Tab.Panel>
								))}
							</Tab.Panels>
						</>
					)}
				</Tab.Group>
			</Container>
		</section>
	);
}
